.App {
    text-align: center;
}

/* 
.btn-nav {
	background-color: white;
	color: #5c080b;
  border: 0.1em solid #5c080b;
  margin: 0.2em;
}

.btn.focus, .btn:focus {
	box-shadow: 0 0 0 0.2rem rgba(92, 8, 11, 0.25);
}



.btn.hover, .btn:hover {
  background-color: #5c080b;
  color: white;
	padding: "1em";
} */

/* .btn-outline-secondary {
    background-color: white;
} */
/* 
.uploadModal {
    min-width: 50%;
} */

.image-gallery-left-nav,
.image-gallery-right-nav {
    padding: 0 !important;
}

.modal-header .close {
    display: none;
}